import {WelcomePanel} from '../components/WelcomePanel';
import {FormEvent, useEffect, useState} from 'react';
import {useAuthContext} from '../hooks/use-auth-context';
import {useNavigate} from 'react-router-dom';

export function LoginPage() {
    const {token, login} = useAuthContext();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (token) {
            navigate('/dashboard');
        }
    }, [token, navigate]);
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        setIsLoading(true);
        setError(null);
        
        try {
            await login(username, password);
            
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            setError(err?.message || 'Login failed');
        }
    };
    
    return (
        <div>
            <WelcomePanel />
            <div className="row justify-content-center mt-5 mx-2">
                <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                    <div className="card shadow" id="login_card">
                        <div className="card-body">
                            <div className="card-title">
                                <h3>Login</h3>
                                <div className="card-text">
                                    <p className="small text-muted">Login with your username &amp; password</p>
                                    {error && (
                                        <div className="alert alert-danger" role="alert" id="login_alert">
                                            {error}
                                        </div>
                                    )}
                                    <form id="login_form" onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <input
                                                type="email" className="form-control form-control mt-2"
                                                placeholder="Email" required name="email"
                                                value={username}
                                                onChange={e => setUsername(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="password" className="form-control form-control mt-2"
                                                placeholder="Password" required name="password"
                                                value={password}
                                                onChange={e => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 d-grid">
                                            <button type="submit"
                                                    className="btn btn-success w-100" name="login_btn"
                                                    disabled={isLoading}
                                                    >
                                                {isLoading ? (
                                                    <span className="spinner-border spinner-border-sm" role="status"
                                                          aria-hidden="true"></span>
                                                ) : (
                                                    'Login'
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
