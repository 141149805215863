import {useAuthContext} from '../../hooks/use-auth-context';

export function AdminProfile() {
    const {admin} = useAuthContext();
    
    if (!admin) {
        return <p>Admin information is unavailable.</p>;
    }
    
    return (
        <div className="container mt-4">
            <h2 className="mb-4">Admin Profile</h2>
            <div className="row">
                <div className="col-12 col-sm-10 col-md-8 col-lg-8">
                    <div className="table-responsive">
                        <table className="table table-bordered table-striped table-hover rounded-3 overflow-hidden">
                            <tbody>
                                <tr>
                                    <th scope="row">Username</th>
                                    <td>{admin.username}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Name</th>
                                    <td>{admin.name}</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="pt-2">Status</th>
                                    <td>
                                <span className={`badge ${admin.status ? 'bg-success' : 'bg-danger'}  fs-6`}>
                                    {admin.status ? 'Active' : 'Inactive'}
                                </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Super Admin</th>
                                    <td>{admin.superAdmin ? 'Yes' : 'No'}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Amount</th>
                                    <td>{Math.round(admin.amount * 100) / 100} GB</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
