import {FormEvent, useRef, useState} from 'react';
import {User} from '../../apis/users';
import {useUserContext} from '../../hooks/use-user-context';
import {CONFIG} from '../../config';

interface UserChangePanelProps {
    user: User;
}

export function UserChangePanel({user}: UserChangePanelProps) {
    const {id, panelName} = user;
    const {changePanel} = useUserContext();
    
    const [newPanelName, setNewPanelName] = useState(CONFIG.panelNames[0]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const closeRef = useRef<HTMLButtonElement>(null);
    
    const renderedPanelNames = CONFIG.panelNames.map((name, index) => {
        return (
            <option key={index} value={name}>{name}</option>
        );
    });
    
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        setError(null);
        setIsLoading(true);
        
        try {
            await changePanel(id, newPanelName);
            
            setIsLoading(false);
            closeRef.current?.click();
        } catch (err: any) {
            setIsLoading(false);
            setError(err?.message || 'Failed to change panel');
        }
    };
    
    return (
        <div
            className="modal fade"
            id={`change_panel_user_${id}`}
            tabIndex={-1}
            aria-labelledby={`change_panel_user_${id}`}
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`change_panel_user_${id}_label`}>
                            Change User Panel
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeRef}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {error && (
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        )}
                        
                        <form onSubmit={handleSubmit} id={`change_panel_user_${id}_form`}>
                            {/* Display current panel name */}
                            <div className="mb-3">
                                <label htmlFor={`currentPanel_${id}`} className="form-label">
                                    Current Panel
                                </label>
                                <input
                                    type="text"
                                    id={`currentPanel_${id}`}
                                    className="form-control"
                                    value={panelName}
                                    readOnly
                                    disabled
                                />
                            </div>
                            
                            {/* Select new panel */}
                            <div className="mb-3">
                                <label htmlFor={`panelSelect_${id}`} className="form-label">
                                    Select New Panel
                                </label>
                                <select
                                    id={`panelSelect_${id}`}
                                    className="form-select form-select-sm"
                                    value={newPanelName}
                                    onChange={(e) => setNewPanelName(e.target.value)}
                                >
                                    {renderedPanelNames}
                                </select>
                            </div>
                            
                            <div className="mb-3">
                                <button
                                    type="submit"
                                    className="btn btn-sm btn-success w-100"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        'Change Panel'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
