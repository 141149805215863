import {createContext, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {Admin, AdminRestClient} from '../apis/admin';
import {useNavigate} from 'react-router-dom';

interface AuthContextType {
    admin: Admin | null;
    token: string | null;
    updateAdmin: () => void;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({children}: {children: ReactNode}) {
    const adminClient = AdminRestClient.getAdminRestClient();
    const navigate = useNavigate();
    const [token, setToken] = useState<string | null>(() => localStorage.getItem('token'));
    const [admin, setAdmin] = useState<Admin | null>(null);
    
    const saveToken = (newToken: string | null) => {
        if (newToken) {
            localStorage.setItem('token', newToken);
        } else {
            localStorage.removeItem('token');
        }
        setToken(newToken);
    };
    
    const login = useCallback(async (username: string, password: string) => {
        const {token} = await adminClient.login(username, password);
        saveToken(token);
        
        const admin = await adminClient.profile(token);
        setAdmin(admin);
        navigate('/dashboard');
    }, [navigate]);
    
    const logout = useCallback(() => {
        saveToken(null);
        setAdmin(null);
        navigate('/login');
    }, [navigate]);
    
    const updateAdmin = useCallback(async () => {
        if (token) {
            try {
                const updatedAdmin = await adminClient.profile(token);
                setAdmin(updatedAdmin);
            } catch (error) {
                logout();
            }
        }
    }, [token, logout]);
    
    useEffect(() => {
        if (token) {
            const verifyToken = async () => {
                try {
                    const admin = await adminClient.profile(token);
                    setAdmin(admin);
                } catch (error) {
                    logout();
                }
            };
            verifyToken().then();
        }
    }, [token, logout]);
    
    const value = useMemo(
        () => ({
            admin,
            token,
            updateAdmin,
            login,
            logout,
        }),
        [admin, token, login, logout],
    );
    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
