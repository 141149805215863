import {UserDetails} from './UserDetails';
import {UserEdit} from './UserEdit';
import {useUserContext} from '../../hooks/use-user-context';
import {Link} from 'react-router-dom';
import {UserCharge} from './UserCharge';
import {UserChangePanel} from './UserChangePanel';

export function UsersList() {
    const {users} = useUserContext();
    
    const renderedUsers = users.map(({id, email, name, token, expire, amount, used}) => {
        const daysLeft = Math.max(0, Math.floor((new Date(expire).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)));
        
        return (
            <tr key={id}>
                <td>{id}</td>
                <td>{email}</td>
                <td>{name}</td>
                <td className="text-center">{Math.round((amount - used) * 100) / 100} (GB)</td>
                <td className="text-center">{daysLeft}</td>
                <td>
                    <Link to={`/users/sub/${token}`} target="_blank" rel="noopener noreferrer">
                        View Subscription
                    </Link>
                </td>
                <td>
                    <button data-bs-toggle="modal" data-bs-target={`#user_details_${id}`}
                            className="btn btn-info btn-sm w-100">
                        Details
                    </button>
                </td>
                <td>
                    <button data-bs-toggle="modal" data-bs-target={`#edit_user_${id}`}
                            className="btn btn-warning btn-sm w-100">
                        Edit
                    </button>
                </td>
                <td>
                    <button data-bs-toggle="modal" data-bs-target={`#charge_user_${id}`}
                            className="btn btn-success btn-sm w-100">
                        Charge
                    </button>
                </td>
                <td>
                    <button data-bs-toggle="modal" data-bs-target={`#change_panel_user_${id}`}
                            className="btn btn-secondary btn-sm w-100 text-nowrap">
                        Change Panel
                    </button>
                </td>
            </tr>
        );
    });
    
    const renderedUserDetailsModals = users.map((user) => {
        return (
            <div key={user.id}>
                <UserDetails user={user} />
            </div>
        );
    });
    
    const renderedEditUsersModals = users.map((user) => {
        return (
            <div key={user.id}>
                <UserEdit user={user} />
            </div>
        );
    });
    
    const renderedUserChangePanelModals = users.map((user) => {
        return (
            <div key={user.id}>
                <UserChangePanel user={user} />
            </div>
        );
    });
    
    const renderedChargeUsersModals = users.map((user) => {
        return (
            <div key={user.id}>
                <UserCharge user={user} />
            </div>
        );
    });
    
    return (
        <div className="table-responsive">
            <table
                className="table table-striped table-hover bg-light table-bordered rounded-3 overflow-hidden shadow small"
                id="users_table"
            >
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Amount Left</th>
                        <th>Days Left</th>
                        <th>Sub Link</th>
                        <th>Details</th>
                        <th>Edit</th>
                        <th>Charge</th>
                        <th>Change Panel</th>
                    </tr>
                </thead>
                <tbody>
                    {renderedUsers}
                </tbody>
            </table>
            
            {/* User Details Modals */}
            {renderedUserDetailsModals}
            
            {/* Edit User Modal */}
            {renderedEditUsersModals}
            
            {/* Charge User Modal */}
            {renderedChargeUsersModals}
            
            {/* Change User Panel Modal */}
            {renderedUserChangePanelModals}
        </div>
    );
}
