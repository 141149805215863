import {createContext, ReactNode, useEffect, useState} from 'react';
import {User, UserRestClient} from '../apis/users';
import {useAuthContext} from '../hooks/use-auth-context';

interface UserContextType {
    users: User[];
    setUsers: (users: User[]) => void;
    createUser: (email: string, name: string, panelName: string, productName: string) => Promise<void>;
    getUsers: () => Promise<void>;
    updateUser: (userId: string, name?: string, email?: string) => Promise<void>;
    chargeUser: (userId: string, productName: string) => Promise<void>;
    changePanel: (userId: string, panelName: string) => Promise<void>;
    enableUser: (userId: string) => Promise<void>;
    disableUser: (userId: string) => Promise<void>;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({children}: {children: ReactNode}) {
    const {token, updateAdmin} = useAuthContext();
    const [users, setUsers] = useState<User[]>([]);
    
    const restClient = UserRestClient.getUsersRestClient();
    
    const getUsers = async () => {
        if (token) {
            const users = await restClient.getUsers(token);
            
            setUsers(users);
        }
    };
    
    const createUser = async (
        email: string, name: string, panelName: string, productName: string,
    ) => {
        if (token) {
            const user = await restClient.createUser(token, email, name, panelName, productName);
            
            updateAdmin();
            setUsers([
                ...users,
                user,
            ]);
        }
    };
    
    const updateUser = async (userId: string, name?: string, email?: string) => {
        if (token) {
            const updatedUser = await restClient.updateUser(token, userId, name, email);
            
            const updatedUsers = users.map(user => {
                if (user.id === userId) {
                    return updatedUser;
                }
                
                return user;
            });
            
            setUsers(updatedUsers);
        }
    };
    
    const chargeUser = async (userId: string, productName: string) => {
        if (token) {
            const updatedUser = await restClient.chargeUser(token, userId, productName);
            
            const updatedUsers = users.map(user => {
                if (user.id === userId) {
                    return updatedUser;
                }
                
                return user;
            });
            
            updateAdmin();
            setUsers(updatedUsers);
        }
    };
    
    const changePanel = async (userId: string, panelName: string) => {
        if (token) {
            const updatedUser = await restClient.changePanel(token, userId, panelName);
            
            const updatedUsers = users.map(user => {
                if (user.id === userId) {
                    return updatedUser;
                }
                
                return user;
            });
            
            setUsers(updatedUsers);
        }
    };
    
    const enableUser = async (userId: string) => {
        if (token) {
            await restClient.enableUser(token, userId);
        }
    };
    
    const disableUser = async (userId: string) => {
        if (token) {
            await restClient.disableUser(token, userId);
        }
    };
    
    useEffect(() => {
        if (token) {
            getUsers().then();
        }
    }, [token]);
    
    const value = {
        users,
        setUsers,
        createUser,
        getUsers,
        updateUser,
        chargeUser,
        changePanel,
        enableUser,
        disableUser,
    };
    
    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}
