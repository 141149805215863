import {createContext, ReactNode, useEffect, useState} from 'react';
import {Product, ProductsRestClient} from '../apis/products';
import {useAuthContext} from '../hooks/use-auth-context';

interface ProductContextType {
    products: Product[];
    setProducts: (products: Product[]) => void;
    createProduct: (name: string, days: number, amount: number) => Promise<void>;
    getProducts: () => Promise<void>;
}

export const ProductContext = createContext<ProductContextType | undefined>(undefined);

export function ProductProvider({children}: {children: ReactNode}) {
    const {token} = useAuthContext();
    const [products, setProducts] = useState<Product[]>([]);
    
    const restClient = ProductsRestClient.getUsersRestClient();
    
    const getProducts = async () => {
        if (token) {
            const products = await restClient.getProducts(token);
            setProducts(products);
        }
    };
    
    const createProduct = async (name: string, days: number, amount: number) => {
        if (token) {
            const product = await restClient.createProduct(token, name, days, amount);
            setProducts([...products, product]);
        }
    };
    
    useEffect(() => {
        if (token) {
            getProducts().then();
        }
    }, [token]);
    
    const value = {
        products,
        setProducts,
        createProduct,
        getProducts,
    };
    
    return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
}
