import {CONFIG} from '../config';
import axios, {AxiosResponse} from 'axios';

export interface Product {
    id: string;
    name: string;
    amount: number;
    days: number;
}

export class ProductsRestClient {
    private static uniqueInstance: ProductsRestClient;
    private url = CONFIG.backendPrefix;
    
    static getUsersRestClient() {
        if (!this.uniqueInstance) {
            this.uniqueInstance = new ProductsRestClient();
        }
        
        return this.uniqueInstance;
    }
    
    async createProduct(
        adminToken: string, name: string, days: number, amount: number,
    ): Promise<Product> {
        let res: AxiosResponse;
        
        try {
            res = await axios.post(`${this.url}/products`, {
                name, days, amount,
            }, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error creating product');
        }
        
        return res.data as Product;
    }
    
    async getProducts(adminToken: string): Promise<Product[]> {
        let res: AxiosResponse;
        
        try {
            res = await axios.get(`${this.url}/products`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error getting products');
        }
        
        return res.data as Product[];
    }
}
