import {UserCreate} from '../components/users/UserCreate';
import React from 'react';
import {UsersList} from '../components/users/UsersList';

export function UsersPage() {
    return (
        <div>
            {/* dashboard contents */}
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-12 col-md-4 col-lg-3">
                        <div className="list-group card-border small shadow">
                            <div className="list-group-item active">Users Data</div>
                            <button className="list-group-item list-group-item-action" data-bs-toggle="modal"
                                    data-bs-target="#add_user">
                                Add User
                            </button>
                            <button className="list-group-item list-group-item-action">
                                View All Users
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-9 mt-3 mt-md-0">
                        <UsersList />
                    </div>
                </div>
            </div>
            
            {/* Add User Modal */}
            <UserCreate />
        </div>
    );
}
