import {FormEvent, useEffect, useRef, useState} from 'react';
import {User} from '../../apis/users';
import {useUserContext} from '../../hooks/use-user-context';
import {useProductContext} from '../../hooks/use-product-context';

interface UserChargeProps {
    user: User;
}

export function UserCharge({user}: UserChargeProps) {
    const {id} = user;
    const {chargeUser} = useUserContext();
    const {products} = useProductContext();
    
    const [productName, setProductName] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const closeRef = useRef<HTMLButtonElement>(null);
    
    useEffect(() => {
        if (products.length > 0) {
            setProductName(products[0]?.name || '');
        }
    }, [products]);
    
    const renderedProductNames = products.map(({name}) => {
        return (
            <option key={name} value={name}>
                {name}
            </option>
        );
    });
    
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true);
        
        try {
            await chargeUser(id, productName);
            
            setIsLoading(false);
            closeRef.current?.click();
        } catch (err: any) {
            setIsLoading(false);
            setError(err?.message || 'Charge failed');
        }
    };
    
    return (
        <div
            className="modal fade" id={`charge_user_${id}`} tabIndex={-1} aria-labelledby={`charge_user_${id}`}
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`charge_user_${id}_label`}>
                            Charge User Account
                        </h5>
                        <button
                            type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            ref={closeRef}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {error && (
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        )}
                        
                        <form onSubmit={handleSubmit} id={`charge_user_${id}_form`}>
                            <div className="mb-3">
                                <label htmlFor={`productSelect_${id}`} className="form-label">
                                    Select Product
                                </label>
                                <select
                                    id={`productSelect_${id}`}
                                    className="form-select form-select-sm"
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                >
                                    {renderedProductNames}
                                </select>
                            </div>
                            
                            <div className="mb-3">
                                <button
                                    type="submit"
                                    className="btn btn-sm btn-success w-100"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                    ) : (
                                        'Charge User'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
