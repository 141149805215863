import React, {useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';
import moment from 'jalali-moment';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function DailyUsageChart({dailyUsage}: {dailyUsage: {date: Date; usage: number}[]}) {
    const [maxEntries, setMaxEntries] = useState(15); // Default for medium+ screens
    
    // Adjust limit based on Bootstrap breakpoints
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            
            // Use Bootstrap breakpoints
            if (width < 576) {
                setMaxEntries(7); // Extra small screens (mobile)
            } else if (width < 768) {
                setMaxEntries(10); // Small screens (tablets)
            } else {
                setMaxEntries(15); // Medium and larger screens (laptops/desktops)
            }
        };
        
        handleResize(); // Check the size on component mount
        window.addEventListener('resize', handleResize); // Listen for window resize
        
        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup on unmount
        };
    }, []);
    
    // Get only the last `maxEntries`
    dailyUsage = dailyUsage.slice(-maxEntries);
    
    const formatDate = (date: Date, format: string = 'YYYY/MM/DD') =>
        date ? moment(date).locale('fa').format(format) : 'N/A';
    
    const labels = dailyUsage.map((entry) => formatDate(entry.date));
    const data = {
        labels,
        datasets: [
            {
                label: 'مصرف روزانه (GB)',
                data: dailyUsage.map((entry) => entry.usage),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };
    
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'top' as const,
            },
        },
        scales: {
            x: {title: {display: true, text: 'تاریخ'}},
            y: {title: {display: true, text: 'مصرف (GB)'}},
        },
        responsive: true,
        maintainAspectRatio: false,
    };
    
    return (
        <div className="mt-3 mb-3" style={{
            width: '100%',
            // maxWidth: '600px', // Limit max width for the chart
            height: '400px',  // Fix height to prevent uncontrolled growth
            margin: '0 auto', // Center the chart horizontally
        }}>
            <Bar data={data} options={options} />
        </div>
    );
}
