export function NotFound() {
    return (
        <div className="row justify-content-center mt-5">
            <div className="col text-center">
                <h1>404 - Page Not Found</h1>
                <p>The page you are looking for does not exist.</p>
            </div>
        </div>
    );
}
