import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from 'react-router-dom';
import {AuthProvider} from './context/Auth';
import {UserProvider} from './context/User';
import {ProductProvider} from './context/Product';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <BrowserRouter>
        <AuthProvider>
            <UserProvider>
                <ProductProvider>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </ProductProvider>
            </UserProvider>
        </AuthProvider>
    </BrowserRouter>,
);
