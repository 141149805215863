export const CONFIG = {
    backendPrefix: '/api',
    panelNames: [
        'master',
        'ssa',
    ],
    productionServer: getEnvVariable('REACT_APP_PRODUCTION_SERVER'),
};

function getEnvVariable(key: string): string {
    const value = process.env[key];
    if (!value) {
        throw new Error(`Missing required environment variable: ${key}`);
    }
    return value;
}
