import axios, {AxiosResponse} from 'axios';
import {CONFIG} from '../config';

export interface LoginRes {
    token: string;
    superAdmin: boolean;
}

export interface Admin {
    id: string;
    username: string;
    name: string;
    status: boolean;
    superAdmin: boolean;
    amount: number;
}

export class AdminRestClient {
    private static uniqueInstance: AdminRestClient;
    private url = CONFIG.backendPrefix;
    
    static getAdminRestClient() {
        if (!this.uniqueInstance) {
            this.uniqueInstance = new AdminRestClient();
        }
        
        return this.uniqueInstance;
    }
    
    async login(username: string, password: string) {
        let res: AxiosResponse;
        
        try {
            res = await axios.post(`${this.url}/admins/login`, {
                username, password,
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error logging admin');
        }
        
        return res.data as LoginRes;
    }
    
    async profile(adminToken: string) {
        let res: AxiosResponse;
        
        try {
            res = await axios.get(`${this.url}/admins/profile`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error getting admin profile');
        }
        
        return res.data as Admin;
    }
}
