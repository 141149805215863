import {FormEvent, useRef, useState} from 'react';
import {User} from '../../apis/users';
import {useUserContext} from '../../hooks/use-user-context';

interface UserEditProps {
    user: User;
}

export function UserEdit({user}: UserEditProps) {
    const {id} = user;
    const {updateUser, enableUser, disableUser} = useUserContext();
    
    const [email, setEmail] = useState(user.email);
    const [name, setName] = useState(user.name);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const closeRef = useRef<HTMLButtonElement>(null);
    
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        setError(null);
        setIsLoading(true);
        
        try {
            await updateUser(id, name, email);
            
            setIsLoading(false);
            closeRef.current?.click();
        } catch (err: any) {
            setIsLoading(false);
            setError(err?.message || 'User update failed');
        }
    };
    
    const handleEnable = async () => {
        try {
            await enableUser(id);
            closeRef.current?.click();
        } catch (err: any) {
            setError(err?.message || 'User enabling failed');
        }
    };
    
    const handleDisable = async () => {
        try {
            await disableUser(id);
            closeRef.current?.click();
        } catch (err: any) {
            setError(err?.message || 'User disabling failed');
        }
    };
    
    return (
        <div
            className="modal fade" id={`edit_user_${id}`} tabIndex={-1} aria-labelledby={`edit_user_${id}`}
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5">
                            Edit User Details
                        </h1>
                        <button
                            type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            ref={closeRef}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {error &&
                          <div className="alert alert-danger" role="alert" id={`edit_user_${id}_alert`}>
                              {error}
                          </div>
                        }
                        <form onSubmit={handleSubmit} id={`edit_user_${id}_form`}>
                            <div className="mb-3">
                                <input
                                    type="email" required name="email" placeholder="Email"
                                    className="form-control form-control-sm"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="text" required name="name" placeholder="Name"
                                    className="form-control form-control-sm"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <button type="submit" className="btn btn-sm btn-success w-100"
                                        name="update_user_btn"
                                        disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                    ) : (
                                        'Update User'
                                    )}
                                </button>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary me-2"
                                    onClick={handleEnable}
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                    ) : (
                                        'Enable User'
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={handleDisable}
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                    ) : (
                                        'Disable User'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
