import {User} from '../../apis/users';
import moment from 'moment';

interface UserDetailsProps {
    user: User;
}

export function UserDetails({user}: UserDetailsProps) {
    const {id, email, name, panelName, adminId, lastEmergencyChargeDate, token, amount, expire, used} = user;
    const formatDate = (date: string) => {
        return moment(date).format('YYYY/MM/DD');
    }
    
    return (
        <div className="modal fade modal-lg" id={`user_details_${id}`} tabIndex={-1}
             aria-labelledby={`user_details_${id}`} aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5"> User Details </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div className="modal-body table-responsive">
                        <table className="table table-striped table-bordered table-hover rounded-2 overflow-hidden small"
                               id={`user_details_${id}_table`}
                        >
                            <tbody>
                                <tr>
                                    <th>Id</th>
                                    <td>{id}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{email}</td>
                                </tr>
                                <tr>
                                    <th>Name</th>
                                    <td>{name}</td>
                                </tr>
                                <tr>
                                    <th>Panel Name</th>
                                    <td>{panelName}</td>
                                </tr>
                                <tr>
                                    <th>Admin Id</th>
                                    <td>{adminId}</td>
                                </tr>
                                <tr>
                                    <th>Token</th>
                                    <td>{token}</td>
                                </tr>
                                <tr>
                                    <th>Last Emergency Charge</th>
                                    <td>{formatDate(lastEmergencyChargeDate.toString())}</td>
                                </tr>
                                <tr>
                                    <th>Expiration Date</th>
                                    <td>{formatDate(expire.toString())}</td>
                                </tr>
                                <tr>
                                    <th>Amount (GB)</th>
                                    <td>{Math.round(amount * 100) / 100}</td>
                                </tr>
                                <tr>
                                    <th>Used (GB)</th>
                                    <td>{Math.round(used * 100) / 100}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
