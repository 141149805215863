import React from 'react';
import {Navbar} from './components/Navbar';
import {LoginPage} from './pages/LoginPage';
import {DashboardPage} from './pages/DashboardPage';
import {UsersPage} from './pages/UsersPage';
import {NotFound} from './components/NotFound';
import {Route, Routes, useLocation} from 'react-router-dom';
import {SubscriptionPage} from './pages/SubscriptionPage';
import {AdminProfile} from './components/admin/AdminProfile';

export function App() {
    const location = useLocation();
    
    const showNavbarPaths = ['/dashboard', '/users', '/profile'];
    const shouldShowNavbar = showNavbarPaths.includes(location.pathname);
    
    return (
        <>
            {shouldShowNavbar && <Navbar />}
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/404" element={<NotFound />} />
                
                {/* Protected Routes */}
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/profile" element={<AdminProfile />} />
                
                {/* Dynamic subscription route */}
                <Route path="/users/sub/:token" element={<SubscriptionPage />} />
                
                {/* Catch-all 404 route */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}
