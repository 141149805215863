import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {UserRestClient, UserSubscription} from '../apis/users';
import {Subscription} from '../components/users/Subscription';
import {NotFound} from '../components/NotFound';

export function SubscriptionPage() {
    const userRestClient = UserRestClient.getUsersRestClient();
    const {token} = useParams<{token: string}>();
    const [subscription, setSubscription] = useState<UserSubscription | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    
    useEffect(() => {
        if (token) {
            const fetchSubscription = async () => {
                try {
                    setLoading(true);
                    const data = await userRestClient.userSubscription(token);
                    setSubscription(data);
                } catch (err: any) {
                    setError('Subscription not found');
                } finally {
                    setLoading(false);
                }
            };
            
            fetchSubscription().then();
        }
    }, [token]);
    
    if (loading) {
        return <div>Loading...</div>;
    }
    
    if (error) {
        return <NotFound />;
    }
    
    if (subscription) {
        return <Subscription subscription={subscription} />;
    }
    
    return null;
}
