import {Dashboard} from '../components/Dashboard';
import {useUserContext} from '../hooks/use-user-context';
import {useAuthContext} from '../hooks/use-auth-context';

export function DashboardPage() {
    const {users} = useUserContext();
    const {admin} = useAuthContext();
    
    const cards = [
        {
            title: <div>{users.length} <small className="text-muted">Users</small></div>,
            link: '/users',
            linkText: 'View All'
        },
    ];
    
    if (admin) {
        cards.unshift({
            title: <div>Profile</div>,
            link: '/profile',
            linkText: 'View Profile'
        })
    }
    
    return (
        <div>
            <div className="container-fluid">
                <Dashboard cards={cards} />
            </div>
        </div>
    );
}
